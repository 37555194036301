import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import AddWallet from "./components/AddWallet/AddWallet";
import AddAdmins from "./components/Admin/AddAdmin";
import Admins from "./components/Admin/Admins";
import Auths from "./components/Auths/Auths";
import ManageAuthPermission from "./components/Auths/ManageAuthPermission";
import AddBanner from "./components/Banner/AddBanner";
import Banner from "./components/Banner/Banner";
import EditBanner from "./components/Banner/EditBanner";
import AddNotice from "./components/Notice/AddNotice";
import EditNotice from "./components/Notice/EditNotice";
import Notice from "./components/Notice/Notice";
import ManageOrderPermission from "./components/Orders/ManageOrderPermission";
import Orders from "./components/Orders/Orders";
import SubadminOrders from "./components/Orders/SubadminOrders";
import AddPackage from "./components/Packages/AddPackage";
import EditPackage from "./components/Packages/EditPackage";
import Packages from "./components/Packages/Packages";
import Voucher from "./components/Packages/Voucher/Voucher";
import AddPaymentMethod from "./components/PaymentMethod/AddPaymentMethod";
import EditPaymentMethod from "./components/PaymentMethod/EditPaymentMethod";
import PaymentMethod from "./components/PaymentMethod/PaymentMethod";
import ChangePassword from "./components/Profile/ChangePassword";
import Profile from "./components/Profile/Profile";
import SendSms from "./components/Sms/SendSms";
import Test from "./components/Test";
import AddTopupProduct from "./components/TopupProduct/AddTopupProduct";
import EditTopupProduct from "./components/TopupProduct/EditTopupProduct";
import TopupProduct from "./components/TopupProduct/TopupProduct";

import AddServer from "./components/AutoServer/AddServer";
import EditServer from "./components/AutoServer/EditServer";
import AutoServer from "./components/AutoServer/AutoServer";

import EditUser from "./components/Users/EditUser";
import Users from "./components/Users/Users";
import AdminLayout from "./layouts/AdminLayout";
import BlankLayout from "./layouts/BlankLayout";
import { isAuth } from "./utils/handler.utils";
import Dashboard from "./views/admin/Dashboard";
import Settings from "./views/admin/Settings";
import Tables from "./views/admin/Tables";
import Login from "./views/auth/Login";
import Register from "./views/auth/Register";
import TopupOrderMessage from "./components/TopupOrderMessage/TopupOrderMessage";
import AddNewMessage from "./components/TopupOrderMessage/AddNewMessage";
import TopupPaymentMethod from "./components/TopupPaymentMethod/TopupPaymentMethod";
import VoucherStatistic from "./components/Packages/Voucher/Statistic";
import AddNewCategory from "./components/Category/AddNewCategory";
import Category from "./components/Category/Category";
import EditCategory from "./components/Category/EditCategory";

function App() {
  const auth = isAuth();
  return (
    <>
      <ToastContainer />
      {auth ? (
        <AdminLayout>
          <Switch>
            <Route path="/" exact component={Dashboard} />

            <Route path="/order" exact component={Orders} />
            <Route path="/subadmin-order" exact component={SubadminOrders} />

            <Route path="/user" exact component={Users} />
            <Route path="/user/edit/:id" exact component={EditUser} />

            <Route path="/payment-method" exact component={PaymentMethod} />
            <Route
              path="/payment-method/add"
              exact
              component={AddPaymentMethod}
            />
            <Route
              path="/payment-method/edit/:id"
              exact
              component={EditPaymentMethod}
            />

            <Route path="/notice" exact component={Notice} />
            <Route path="/notice/add" exact component={AddNotice} />
            <Route path="/notice/edit/:id" exact component={EditNotice} />

            <Route path="/topup-product" exact component={TopupProduct} />
            <Route
              path="/topup-product/add"
              exact
              component={AddTopupProduct}
            />
            <Route
              path="/topup-product/edit/:id"
              exact
              component={EditTopupProduct}
            />

            <Route path="/auto-server" exact component={AutoServer} />
            <Route path="/auto-server/add" exact component={AddServer} />
            <Route path="/auto-server/edit/:id" exact component={EditServer} />

            <Route path="/banner" exact component={Banner} />
            <Route path="/banner/add" exact component={AddBanner} />
            <Route path="/banner/edit/:id" exact component={EditBanner} />

            <Route path="/admins" exact component={Admins} />
            <Route path="/admin/add" exact component={AddAdmins} />

            <Route path="/add-wallet" exact component={AddWallet} />
            {/* <Route path="/add-wallet/edit/:id" exact component={EditTransaction} /> */}

            <Route
              path="/manage-auth-permission/:id"
              exact
              component={ManageAuthPermission}
            />
            <Route
              path="/manage-order-permission/:id"
              exact
              component={ManageOrderPermission}
            />

            <Route path="/auths" exact component={Auths} />
            <Route path="/settings" exact component={Settings} />
            <Route path="/tables" exact component={Tables} />
            <Route path="/register" exact component={Register} />
            <Route path="/profile" exact component={Profile} />
            <Route
              path="/profile/change-password"
              exact
              component={ChangePassword}
            />

            <Route path="/category" exact component={Category} />
            <Route path="/category/add-new" exact component={AddNewCategory} />
            <Route path="/category/edit/:id" exact component={EditCategory} />

            <Route path="/topup-packages" exact component={Packages} />
            <Route
              path="/topup-package/edit/:id"
              exact
              component={EditPackage}
            />
            <Route path="/topup-package/add/:id" exact component={AddPackage} />

            <Route
              path="/topup-package/voucher/:id"
              exact
              component={Voucher}
            />
            <Route
              path="/topup-package/voucher-statistic"
              exact
              component={VoucherStatistic}
            />

            <Route
              path="/topup-payment-method"
              exact
              component={TopupPaymentMethod}
            />

            <Route path="/send-sms" exact component={SendSms} />

            <Route
              path="/topup-order-message"
              exact
              component={TopupOrderMessage}
            />
            <Route
              path="/topup-order-message/add-new"
              exact
              component={AddNewMessage}
            />

            <Route path="/test" exact component={Test} />
            <Redirect from="/" to="/" />
          </Switch>
        </AdminLayout>
      ) : (
        <BlankLayout>
          <Switch>
            <Route path="/login" exact component={Login} />
            <Redirect from="/" to="/login" />
          </Switch>
        </BlankLayout>
      )}
    </>
  );
}

export default App;
